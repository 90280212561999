import queryString from 'query-string';

const NONE_OPTION = {
  id: 'none',
  name: 'N/A',
};

const handleAllFilterChange = ({
  allIds,
  handleQueryChange,
  isChecked,
  queryKey,
  queryParamsParsed,
}) => {
  const newQueryParamsParsed = { ...queryParamsParsed };
  if (isChecked) {
    newQueryParamsParsed[queryKey] = allIds.join(',');
  } else {
    delete newQueryParamsParsed[queryKey];
  }
  handleQueryChange(queryString.stringify(newQueryParamsParsed));
};

const getQueryKeyValues = (queryKeyParam) =>
  (Array.isArray(queryKeyParam) ? queryKeyParam : queryKeyParam?.split(',')) ||
  [];

const isAllChecked = ({ filterOptions, queryKey, queryParamsParsed }) => {
  const queryKeyValues = getQueryKeyValues(queryParamsParsed[queryKey]);
  const dataIdsLength = filterOptions
    .map((item) => {
      let idsLength = 1;
      if (item.id.toString().includes(',')) {
        idsLength = item.id.split(',').length;
      }
      return idsLength;
    })
    .reduce((a, b) => a + b, 0);
  return queryKeyValues?.length === dataIdsLength;
};

const isAnyChecked = ({ filterOptions, queryKey, queryParamsParsed }) => {
  const queryKeyValues = getQueryKeyValues(queryParamsParsed[queryKey]);

  const checkItems = (items) =>
    items.some((item) => {
      const itemIds = item.id.toString().split(',');
      const isChecked = itemIds.some((id) => queryKeyValues.includes(id));
      return isChecked || (item.children && checkItems(item.children));
    });

  return checkItems(filterOptions);
};

const handleParentFilterChange = ({
  handleQueryChange,
  isChecked,
  parentOption,
  queryKey,
  queryParamsParsed,
}) => {
  const queryKeyValues = getQueryKeyValues(queryParamsParsed[queryKey]);
  const childrenIds = parentOption.children?.map((child) =>
    child.id.toString()
  );
  if (isChecked) {
    if (queryKeyValues.length > 0) {
      queryKeyValues.forEach((queryKeyValue) => {
        if (childrenIds?.includes(queryKeyValue)) {
          queryKeyValues.splice(queryKeyValues.indexOf(queryKeyValue), 1);
        }
      });
    }
    queryKeyValues.push(parentOption.id.toString());
  } else {
    queryKeyValues.splice(
      queryKeyValues.indexOf(parentOption.id.toString()),
      1
    );
  }
  const newQueryParamsParsed = { ...queryParamsParsed };
  if (queryKeyValues.length === 0) {
    delete newQueryParamsParsed[queryKey];
  } else {
    newQueryParamsParsed[queryKey] = queryKeyValues.join(',');
  }
  handleQueryChange(queryString.stringify(newQueryParamsParsed));
};

const isParentOptionChecked = ({ parentId, queryKey, queryParamsParsed }) => {
  const queryKeyValues = getQueryKeyValues(queryParamsParsed[queryKey]);
  if (parentId.toString().includes(',')) {
    const parentIds = parentId.toString().split(',');
    return parentIds.every((parentId) =>
      queryKeyValues?.includes(parentId.toString())
    );
  }
  return queryKeyValues?.includes(parentId.toString());
};

const isParentOptionIndeterminate = ({
  childrenIds,
  queryKey,
  queryParamsParsed,
}) => {
  const queryKeyValues = getQueryKeyValues(queryParamsParsed[queryKey]);

  return queryKeyValues?.some((queryKeyValue) =>
    childrenIds?.includes(queryKeyValue)
  );
};

const handleChildFilterChange = ({
  handleQueryChange,
  isChecked,
  childOption,
  parentOption,
  queryKey,
  queryParamsParsed,
}) => {
  const queryKeyValues = getQueryKeyValues(queryParamsParsed[queryKey]);
  const siblingIds = parentOption.children
    .map((child) =>
      child.id.toString() !== childOption.id.toString() ? child.id : null
    )
    .filter(Boolean);
  const allSiblingsInQuery = siblingIds.every((siblingId) =>
    queryKeyValues.includes(siblingId.toString())
  );
  if (isChecked) {
    if (allSiblingsInQuery) {
      siblingIds.forEach((siblingId) => {
        queryKeyValues.splice(queryKeyValues.indexOf(siblingId), 1);
      });
      queryKeyValues.push(parentOption.id.toString());
    } else {
      queryKeyValues.push(childOption.id.toString());
    }
  } else if (queryKeyValues.includes(parentOption.id.toString())) {
    queryKeyValues.splice(
      queryKeyValues.indexOf(parentOption.id.toString()),
      1
    );
    queryKeyValues.push(...siblingIds);
  } else {
    queryKeyValues.splice(queryKeyValues.indexOf(childOption.id.toString()), 1);
  }
  const newQueryParamsParsed = { ...queryParamsParsed };
  if (queryKeyValues.length === 0) {
    delete newQueryParamsParsed[queryKey];
  } else {
    newQueryParamsParsed[queryKey] = queryKeyValues.join(',');
  }
  handleQueryChange(queryString.stringify(newQueryParamsParsed));
};

const handleClearFilter = ({
  handleQueryChange,
  queryKey,
  queryParamsParsed,
}) => {
  const newQueryParamsParsed = { ...queryParamsParsed };
  delete newQueryParamsParsed[queryKey];
  handleQueryChange(queryString.stringify(newQueryParamsParsed));
};

const isChildOptionChecked = ({
  optionId,
  parentId,
  queryKey,
  queryParamsParsed,
}) => {
  const queryKeyValues = getQueryKeyValues(queryParamsParsed[queryKey]);
  return (
    queryKeyValues?.includes(optionId.toString()) ||
    queryKeyValues?.includes(parentId.toString())
  );
};

export const createCheckboxFilter = ({
  data,
  handleQueryChange,
  key,
  name,
  queryParamsParsed,
  selectAllOptions = {},
  showSearch = true,
  showSelectAll = true,
  showSelectNull = false,
}) => {
  const filterOptions = [...data];

  if (showSelectNull) {
    filterOptions.unshift(NONE_OPTION);
  }

  const filterObject = {
    clear: () => {
      handleClearFilter({
        handleQueryChange,
        queryKey: key,
        queryParamsParsed,
      });
    },
    key,
    title: name,
    options: [
      ...filterOptions.map((item) => ({
        action: (val) => {
          handleParentFilterChange({
            handleQueryChange,
            isChecked: val.checked,
            parentOption: item,
            queryKey: key,
            queryParamsParsed,
          });
        },
        children: item.children?.map((child) => ({
          action: (val) => {
            handleChildFilterChange({
              childOption: child,
              handleQueryChange,
              isChecked: val.checked,
              parentOption: item,
              queryKey: key,
              queryParamsParsed,
            });
          },
          isChecked: isChildOptionChecked({
            optionId: child.id,
            parentId: item.id,
            queryKey: key,
            queryParamsParsed,
          }),
          key: `${child.id}-${child.name}`,
          label: child.name,
          level: 2,
          type: 'checkbox',
          value: child.id.toString(),
        })),
        indeterminate: isParentOptionIndeterminate({
          childrenIds: item.children?.map((child) => child.id.toString()),
          queryKey: key,
          queryParamsParsed,
        }),
        isChecked: isParentOptionChecked({
          parentId: item.id,
          queryKey: key,
          queryParamsParsed,
        }),
        key: `${item.id}-${item.name}`,
        label: item.name,
        level: 1,
        type: 'checkbox',
        value: item.id.toString(),
        isOverflowHidden: item.isOverflowHidden,
      })),
    ],
    showClear: true,
    showSearch,
  };

  if (showSelectAll) {
    filterObject.options.unshift({
      action: (val) => {
        handleAllFilterChange({
          allIds: (showSelectNull ? [NONE_OPTION.id] : []).concat(
            filterOptions.map((item) => item.id.toString())
          ),
          handleQueryChange,
          isChecked: val.checked,
          queryKey: key,
          queryParamsParsed,
        });
      },
      isChecked: isAllChecked({
        filterOptions,
        queryKey: key,
        queryParamsParsed,
      }),
      key: `${key}-all`,
      label: 'Select All',
      level: 1,
      type: 'switch',
      indeterminate:
        !isAllChecked({ filterOptions, queryKey: key, queryParamsParsed }) &&
        isAnyChecked({
          filterOptions,
          queryKey: key,
          queryParamsParsed,
        }),
      value: 'all',
      ...selectAllOptions,
    });
  }
  return filterObject;
};

const handleRangeChange = ({
  handleQueryChange,
  key,
  queryParamsParsed,
  range,
  value,
}) => {
  const newQueryParamsParsed = { ...queryParamsParsed };
  if (
    Number(value.min) !== Number(range.initialMinValue) ||
    Number(value.max) !== Number(range.initialMaxValue)
  ) {
    if (
      Number(value.min) === (Number(range.min) || 0) &&
      Number(value.max) === (Number(range.max) || 100)
    ) {
      if (newQueryParamsParsed[key]) {
        delete newQueryParamsParsed[key];
        handleQueryChange(queryString.stringify(newQueryParamsParsed));
      }
    } else {
      newQueryParamsParsed[key] = `${Number(value.min)}-${Number(value.max)}`;
      handleQueryChange(queryString.stringify(newQueryParamsParsed));
    }
  }
};

export const createRangeFilter = ({
  handleQueryChange,
  label,
  key,
  name,
  queryParamsParsed,
  range,
}) => ({
  key,
  title: name,
  options: [
    {
      action: ({ value }) => {
        handleRangeChange({
          handleQueryChange,
          key,
          queryParamsParsed,
          range,
          value,
        });
      },
      initialMinValue: Number(range.initialMinValue) || 0,
      initialMaxValue: Number(range.initialMaxValue) || 100,
      key: `${key}-range`,
      label,
      level: 1,
      min: Number(range.min) || 0,
      max: Number(range.max) || 100,
      showReset: true,
      type: 'multi-range-slider',
    },
  ],
});
