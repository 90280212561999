import { getCookie } from '../../ui';
import { JSONStringifyAndKeepUndefinedKeys } from '../../utils';

export async function jsonPatch(url, body) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken'),
    },
    method: 'PATCH',
  };
  if (body) {
    requestOptions.body = JSONStringifyAndKeepUndefinedKeys(body);
  }
  return fetch(url, requestOptions);
}

export async function jsonPut(url, body) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken'),
    },
    method: 'PUT',
  };
  if (body) {
    requestOptions.body = JSONStringifyAndKeepUndefinedKeys(body);
  }
  return fetch(url, requestOptions);
}

export async function jsonPost(url, body) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken'),
    },
    method: 'POST',
  };
  if (body) {
    requestOptions.body =
      body instanceof FormData ? body : JSONStringifyAndKeepUndefinedKeys(body);
  }
  return fetch(url, requestOptions);
}

export async function jsonGet(url, options = {}) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  };
  return fetch(url, requestOptions);
}

export async function jsonDelete(url) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken'),
    },
    method: 'DELETE',
  };
  return fetch(url, requestOptions);
}
