export const DEFAULT_FILTER = {
  isActive: 'true',
};

const userState = {
  filters: DEFAULT_FILTER,
  isUsersUpdating: false,
  downloadedUser: {},
  viewMode: null,
};

export default userState;
