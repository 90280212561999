import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  newToastNotification,
  ToastType,
} from '../../dashboard/components/ToastNotifications/toasts';
import { getCookie } from '../../dashboard/ui';

const baseQuery = fetchBaseQuery({
  baseUrl: '/dashboard/',
  prepareHeaders: (headers) => {
    const csrfToken = getCookie('csrftoken');
    if (csrfToken) {
      headers.set('X-CSRFToken', csrfToken);
    }
    return headers;
  },
});

export const baseQueryWithToast = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    newToastNotification({
      body: 'An error occurred',
      toastType: ToastType.ERROR,
    });
  }
  return result;
};
